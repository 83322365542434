import Link from "next/link";
import { config } from "../metamask/config";
import moment from "moment";

const BidListTable = ({ bids }) => {
  if (bids?.length === 0) {
    return <h1>There is not bids list</h1>
  }
  return (
    <>
      {/* <!-- Activity --> */}
      <div
        className="tab-pane fade"
        id="activity"
        role="tabpanel"
        aria-labelledby="activity-tab"
      >
        <div
          role="table"
          className="scrollbar-custom dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 max-h-72 w-full overflow-y-auto rounded-lg rounded-tl-none border bg-white text-sm dark:text-white"
        >
          <div
            className="dark:bg-jacarta-600 bg-light-base sticky top-0 flex"
            role="row"
          >
            <div className="flex-1 py-2 px-4" role="columnheader">
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Amount
              </span>
            </div>
            <div className="flex-1 py-2 px-4" role="columnheader">
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Bidder
              </span>
            </div>
            <div className="flex-1 py-2 px-4" role="columnheader">
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                From
              </span>
            </div>
            <div className="flex-1 py-2 px-4" role="columnheader">
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Hash
              </span>
            </div>
            <div className="flex-1 py-2 px-4" role="columnheader">
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Date
              </span>
            </div>
            <div className="flex-1 py-2 px-4" role="columnheader">
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Type
              </span>
            </div>
          </div>
          {bids.map((item, index) => {
            const { id, amount, bidderName, trxtype, fromAddress, toAddress, datetime, hash } = item;
            return (
              <div className="flex" role="row" key={id}>
                <div
                  className="dark:border-jacarta-600 flex-2 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >

                  <span className="text-green text-sm font-medium tracking-tight">
                    {amount} MATIC
                  </span>
                </div>
                <div
                  className="dark:border-jacarta-600 flex-1 border-jacarta-100 flex items-center whitespace-nowrap border-t py-4 px-4"
                  role="cell"
                >
                  <span className="-ml-1" data-tippy-content="ETH">
                    <svg className="icon mr-1 h-4 w-4">
                      <use xlinkHref="icons.svg#icon-ETH"></use>
                    </svg>
                  </span>
                  <span className="text-green text-sm font-medium">
                    {bidderName}
                  </span>
                </div>
                <div
                  className="dark:border-jacarta-600 flex-1 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                  <a target="_blank" href={`${config.BASEURL_BLOCKCHAIN}/address/${fromAddress}`} className="text-accent">
                    {fromAddress?.slice(0, 10)}..
                  </a>
                </div>
                <div
                  className="dark:border-jacarta-600 flex-1 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                  <a target="_blank" href={`${config.BASEURL_BLOCKCHAIN}/tx/${hash}`} className="text-accent">
                    {hash?.slice(0, 10)}..
                  </a>
                </div>
                <div
                  className="dark:border-jacarta-600 flex-4 md:flex-2 border-jacarta-100 flex items-center border-t py-4 px-4"
                  role="cell"
                >
                  <span className="mr-1 !text-[12px] md:text-sm">{moment(datetime).format('ll')}</span>
                </div>

                <div
                  className="dark:border-jacarta-600 flex-1 border-jacarta-100 flex items-center whitespace-nowrap border-t py-4 px-4"
                  role="cell"
                >
                  <span className="-ml-1" data-tippy-content="ETH">
                    <svg className="icon mr-1 h-4 w-4">
                      <use xlinkHref="icons.svg#icon-ETH"></use>
                    </svg>
                  </span>
                  <span className=" text-sm font-medium">
                    {trxtype}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  )
}

export default BidListTable