import Tippy from "@tippyjs/react";
import React, { useEffect, useState } from "react";
import { useNFTLikeMutation } from "../redux/actions/nft";
import { ERROR } from "../utils/errors";

const Likes = ({
  isLiked,
  userId,
  nftId,
  like,
  classes = "dark:bg-jacarta-700 absolute top-3 right-3 flex items-center space-x-1 rounded-md bg-white p-2",
}) => {
  
  const [likeNft] = useNFTLikeMutation()
  const [likeNumber, setlikeNumber] = useState(Number(like) > 0 ? Number(like) : 0);
  const [likedByme , setLikedByMe] = useState(parseInt(isLiked || 0))

  const handleLike = () => {
    
    likeNft({ user_id: userId, nft_id: nftId }).unwrap().then(response => {
      if (!response?.status) {
        ERROR(response?.message)
      } else {
        if (response.isLiked) {
          setlikeNumber(parseInt(likeNumber) + 1)
          setLikedByMe(1)
        } else {
          setlikeNumber(parseInt(likeNumber) - 1)
          setLikedByMe(0)
        }
      }
    }).catch(err => {
      ERROR('Somthing went wrong')
    });
  };

  return (
    <div className={classes} onClick={() => {
      if (userId) {
        handleLike();
      }
    }}>
      <Tippy content={<span className="p-2">Favorite</span>}>
        <button className="js-likes relative cursor-pointer">
          {likedByme == 0 ? (
            <svg className="icon icon-heart-fill dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
              <use xlinkHref="/icons.svg#icon-hert-fill"></use>
            </svg>
          ) : (
            <svg className="icon icon-heart-fill dark:fill-jacarta-200 fill-jacarta-500 hover:fill-red dark:hover:fill-red h-4 w-4">
              <use xlinkHref="/icons.svg#icon-heart-fill"></use>
            </svg>
          )}
        </button>
      </Tippy>
      <span className="dark:text-jacarta-200 text-sm text-[black]">{Number(likeNumber) > 0 ? likeNumber : 0}</span>
    </div>
  );
};

export default Likes;
