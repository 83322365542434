import React, { useEffect, useState } from "react";
import { useBidListMutation } from "../../redux/actions/nft";
import { ERROR, Success, Warning } from "../../utils/errors";
import BidListTable from "../tables/BidListTable";
import { blockchainCloseAuction } from './../metamask/blockchain';
import { Spin } from 'antd';
import { useSelector } from "react-redux";

const ViewBidsModal = ({ user_id, nft_id }) => {
  console.log(nft_id, user_id)
  const [showLoading, setShowLoading] = useState(false)
  const loginDetails = useSelector((state) => state.counter.loginDetails);
  const [state, setState] = useState(false)
  const [bidList, setBidList] = useState([]);
  const [bids, { isLoading }] = useBidListMutation()
  useEffect(() => {
    bids({ user_id, nft_id }).unwrap().then(response => {
      console.log(response)
      if (response?.status) {
        setBidList(response?.data)
      }

    }).catch(err => {
      ERROR('Somthing went wrong')
    })
  }, [user_id, nft_id])

  const CloseAuction = async () => {
    setShowLoading(true)
    try {
      const res = await blockchainCloseAuction({
        nft_id: nft_id
      })

      if (res.status) {
        Success("Transaction successful, It will take few minutes for confirmation")
        setState(false)
      } else {
        Warning(res.message)
      }

      setShowLoading(false)
    } catch (err) {
      Warning(err.message)
    }
  }

  return (
    <>
      <button onClick={() => setState(true)} className="inline-block bg-accent text-white px-3 py-2 rounded capitalize text-xs font-medium">view bids</button>
      <div className={state ? "modal fade show block " : "modal fade"}>

        <div className="modal-dialog  max-w-3xl !px-20 lg:min-w-[1000px]">
          <div className="modal-content  px-4">
            <Spin spinning={showLoading} tip="Loading...">
              <div className="modal-header">
                <h5 className="modal-title" id="placeBidLabel">
                  List of bids
                </h5>
                {loginDetails?.profile?.id === user_id && <button onClick={() => CloseAuction()} className="inline-block bg-accent text-white mr-10 px-3 py-2 rounded capitalize text-xs font-medium">Close Auction</button> }

                <button
                  type="button"
                  className="btn-close inline-block"
                  onClick={() => setState(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
                  </svg>
                </button>

              </div>

              {/* <!-- Body --> */}
              <div className="modal-body w-full p-6">

                <BidListTable bids={bidList} />


              </div>
              {/* <!-- end body --> */}
            </Spin>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewBidsModal;
