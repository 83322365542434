import React, { useEffect, useState } from "react";
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Input from "../inputs/Input";

import Button from "../buttons/Button";
import { ERROR, Success, Warning } from "../../utils/errors";
import { blockchainPutOnSale } from "./../metamask/blockchain";
import { useTheme } from "next-themes";
import { Spin } from "antd";

import Select from "../dropdown/SaleType";
import { useDispatch, useSelector } from "react-redux";
import { setUserProcessing } from "../../redux/counterSlice";
import dayjs from "dayjs";
import { ThemeProvider, createTheme } from "@mui/material";
import useTranslation from "next-translate/useTranslation";

const PutOnSaleModal = ({ nftId, nftName, royiti, quantity }) => {
  const {t,lang} = useTranslation('common')
  const { theme } = useTheme();
  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#475569", // Your custom primary color
      },
      success: {
        main: "#334155", // Your custom success color
      },
    },
  });
  const styles = {
    dark: {
      backgroundColor: "jacarta-700",
      color: "success",
      focused: true,
    },
    light: {
      backgroundColor: "white",
      color: "primary",
      focused: false,
    },
  };
  const textFieldStyles = theme === "dark" ? styles.dark : styles.light;

  const UserProcessing = useSelector((state) => state.counter.UserProcessing);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    quantity: quantity,
    price: "",
    saleType: "",
  });
  const [dates, setDates] = useState({
    start: dayjs(new Date()),
    end: dayjs(new Date()),
  });
  const [modalValue, setModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [dateStatus, setDateStatus] = useState(false);
  const datesHandle = (e) => {
    console.log(e.target.value);
    setDates({ ...dates, [e.target.name]: e.target.value });
  };
  const onChange = (e) => {
    if (e.target.name === "saleType") {
      setDateStatus(e.target.value == 2);
    }

    setState({ ...state, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    setState({
      quantity,
      royiti,
    });
  }, []);

  const putOnSale = async () => {
    if (state.quantity > quantity || state.quantity == 0) {
      Warning("quantity should be less than or equal " + quantity);
      return;
    }

    if (parseFloat(state.price || 0) == 0) {
      Warning("Sale Price should be greater than 0");
      return;
    }

    if (!state.saleType) {
      Warning("Invalid sale type");
      return;
    }
    let startDate = 0;
    let endDate = 0;
    if (state.saleType == 2) {
      startDate = new Date(dates.start);
      endDate = new Date(dates.end);

      if (startDate && endDate && startDate >= endDate) {
        Warning("End auction date should be greater than start date");
        return;
      }
    }

    setShowLoading(true);
    try {
      const res = await blockchainPutOnSale({
        quantity: state.quantity,
        nftId: nftId,
        price: state.price,
        saleType: state.saleType,
        startBid: !startDate ? "" : startDate.getTime(),
        endBid: !endDate ? "" : endDate.getTime(),
      });

      if (res.status) {
        dispatch(
          setUserProcessing({
            onsale: nftId,
            hash: res.hash,
          })
        );

        Success(
          "Transaction successful, It will take few minutes for confirmation"
        );
        setModal(false);
      } else {
        Warning(res.message);
      }

      setShowLoading(false);
    } catch (err) {
      Warning(err.toString());
      setShowLoading(false);
    }
  };
  const openModel = () => {
    if (UserProcessing?.onsale == nftId) {
      Warning(
        "The transaction is under process, when it is put on sell you can see the NFT in the On sale NFT section."
      );
      return;
    }
    setModal(true);
  };
  console.log(dates);
  const selectedSize = () => {
    setSelectedSize("any value");
  };
  return (
    <div>
      <button
        onClick={openModel}
        className="inline-block text-sm font-medium capitalize bg-white rounded-lg px-3 py-1 border-jacarta-100 border dark:border-accent-dark shadow-xl hover:border-black hover:bg-black dark:bg-accent-dark dark:text-white hover:text-white group dark:hover:text-white text-jacarta-500 group hover:dark:bg-black hover:dark:border-black"
      >
        {t('list_sale')} <span className="group-hover:pl-3 transition-all">&rarr;</span>
      </button>
      {/* <!-- Modal --> */}
      <div className={modalValue ? "modal fade show block" : "modal fade"}>
        <div className="!my-0 flex h-full w-full  max-w-4xl items-center justify-center">
          <div className="w-full lg:w-8/12 px-3">
            <Spin spinning={showLoading} tip="Loading...">
              <div className="bg-white dark:bg-[#0D102D] px-5 py-10 rounded-md">
                <h1 className="text-xl">{nftName}</h1>
                {/* <!-- Name --> */}
                <div className="my-5">
                  <Input
                    label={t('quantity')}
                    value={state.quantity}
                    onChange={onChange}
                    name="quantity"
                  />
                </div>
                <div className="my-5">
                  <Input
                    label={t('price') + " (MATIC)"}
                    value={state.price}
                    onChange={onChange}
                    name="price"
                  />
                </div>
                <div className="my-5">
                  <label
                    htmlFor="item-name"
                    className="font-display text-jacarta-700 mb-2 block dark:text-white"
                  >
                    {t('sale_type')}<span className="text-red">*</span>
                  </label>

                  <select
                    type="text"
                    name="saleType"
                    value={state.saleType}
                    onChange={onChange}
                    id="item-name"
                    className="dark:bg-black border-accent-dark hover:cursor-pointer hover:ring-accent/10 focus:ring-accent dark:border-accent-dark dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                    placeholder="Item name"
                    required
                  >
                    <option value={""}>{t('select_type')}</option>
                    <option value={1}>{t('fixed_price')}</option>
                    <option value={2}>{t('sale_bid')}</option>
                  </select>
                </div>
                {dateStatus && (
                  <div className="my-8 flex justify-between space-x-3">
                    <div className="w-full">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ThemeProvider theme={customTheme}>
                          <DateTimePicker
                            label={t('start_date')}
                            value={dates.start}
                            onChange={(value) =>
                              setDates({
                                ...dates,
                                start: value?.toISOString(),
                              })
                            }
                            name="start"
                            className="dark:bg-black"
                            slotProps={{
                              textField: () => ({
                                color: textFieldStyles.color,
                                focused: textFieldStyles.focused,
                                sx: {
                                  label: {
                                    color:
                                      theme === "dark"
                                        ? "white!important"
                                        : "black",
                                    "font-weight": "bold",
                                  },
                                },
                                InputProps: {
                                  sx: {
                                    color: theme === "dark" ? "white" : "black",
                                    "& .MuiSvgIcon-root": {
                                      color:
                                        theme === "dark" ? "white" : "black",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "1px solid #7444FF !important",
                                    },
                                    "& input:focus": {
                                      boxShadow: "none",
                                    },
                                  },
                                },
                              }),
                              popper: {
                                sx: {
                                  "& .MuiPaper-root": {
                                    background:
                                      theme === "dark"
                                        ? "black !important"
                                        : "white",
                                    color:
                                      theme === "dark"
                                        ? "white!important"
                                        : "black",
                                  },
                                  "& .MuiPickersArrowSwitcher-button": {
                                    color:
                                      theme === "dark"
                                        ? "#ffffff!important"
                                        : "#000",
                                  },
                                  "& .css-7lip4c, .css-12ha4i7, .css-118whkv, .Mui-selected, .css-a1rc6s":
                                    {
                                      color: "white",
                                    },
                                  "& .css-iubicn-MuiButtonBase-root-MuiPickersDay-root":
                                    {
                                      color:
                                        theme === "dark"
                                          ? "#fff!important"
                                          : "black",
                                    },
                                  "& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                                    {
                                      color: theme === "dark" ? "#fff" : "#000",
                                    },
                                  "& .css-1wbosa2-MuiButtonBase-root-MuiButton-root":
                                    {
                                      color:
                                        theme === "dark"
                                          ? "#fff!important"
                                          : "black",
                                    },
                                  "& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon":
                                    {
                                      color:
                                        theme === "dark"
                                          ? "#fff!important"
                                          : "black",
                                    },
                                  "& li[aria-selected='true']": {
                                    background:
                                      theme !== "dark" && "black !important",
                                  },
                                  "& button[aria-selected='true']": {
                                    background:
                                      theme !== "dark" && "black !important",
                                  },
                                  "& .css-v70tek-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)":
                                    {
                                      borderColor:
                                      theme === "dark"
                                      ? "#fff!important"
                                      : "black",
                                        color:
                                        theme === "dark"
                                          ? "#fff!important"
                                          : "black",
                                    },
                                  "& .Mui-selected": {
                                    color: "white",
                                  },
                                },
                                "& .MuiCalendarPicker-root": {
                                  background: "red",
                                },
                              },
                            }}
                          />
                        </ThemeProvider>
                      </LocalizationProvider>
                    </div>
                    <div className="w-full">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <ThemeProvider theme={customTheme}>
                          <DateTimePicker
                            label={t('end_date')}
                            value={dates.end}
                            onChange={(value) =>
                              setDates({ ...dates, end: value?.toISOString() })
                            }
                            name="end"
                            className="dark:bg-black"
                            slotProps={{
                              textField: () => ({
                                color: textFieldStyles.color,
                                focused: textFieldStyles.focused,
                                sx: {
                                  label: {
                                    color:
                                      theme === "dark"
                                        ? "white!important"
                                        : "black",
                                    "font-weight": "bold",
                                  },
                                },
                                InputProps: {
                                  sx: {
                                    color:
                                      theme === "dark"
                                        ? "white!important"
                                        : "black",
                                    "& .MuiSvgIcon-root": {
                                      color:
                                        theme === "dark"
                                          ? "white!important"
                                          : "black",
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                      border: "1px solid #7444FF !important",
                                    },
                                    "& input:focus": {
                                      boxShadow: "none",
                                    },
                                  },
                                },
                              }),
                              popper: {
                                sx: {
                                  "& .MuiPaper-root": {
                                    background:
                                      theme === "dark"
                                        ? "black !important"
                                        : "white",
                                    color:
                                      theme === "dark"
                                        ? "white!important"
                                        : "black",
                                  },
                                  "& .MuiPickersArrowSwitcher-button": {
                                    color:
                                      theme === "dark"
                                        ? "#ffffff!important"
                                        : "#000",
                                  },
                                  "& .css-7lip4c, .css-12ha4i7, .css-118whkv, .Mui-selected, .css-a1rc6s":
                                    {
                                      color: "white",
                                    },
                                  "& .css-iubicn-MuiButtonBase-root-MuiPickersDay-root":
                                    {
                                      color:
                                        theme === "dark"
                                          ? "#fff!important"
                                          : "black",
                                    },
                                  "& .css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel":
                                    {
                                      color: theme === "dark" ? "#fff" : "#000",
                                    },
                                  "& .css-1wbosa2-MuiButtonBase-root-MuiButton-root":
                                    {
                                      color:
                                        theme === "dark" ? "white" : "black",
                                    },
                                  "& li[aria-selected='true']": {
                                    background:
                                      theme !== "dark" && "black !important",
                                  },
                                  "& button[aria-selected='true']": {
                                    background:
                                      theme !== "dark" && "black !important",
                                  },
                                  "& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon":
                                    {
                                      color:
                                        theme === "dark" ? "white" : "black",
                                    },
                                  "& .css-v70tek-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected)":
                                    {
                                      borderColor:
                                        theme === "dark" ? "white" : "black",
                                      color:
                                        theme === "dark" ? "white" : "black",
                                    },
                                  "& .Mui-selected": {
                                    color: "white",
                                  },
                                },
                                "& .MuiCalendarPicker-root": {
                                  background: "red",
                                },
                              },
                            }}
                          />
                        </ThemeProvider>
                      </LocalizationProvider>
                    </div>
                  </div>
                )}

                <div className="mt-6 flex justify-end gap-3">
                  <button
                    onClick={() => setModal(false)}
                    className="bg-accent dark:bg-white dark:text-black px-6 rounded-full text-white capitalize font-medium cursor-pointer"
                  >
                    {t('cancel')}
                  </button>

                  <button
                    onClick={() => putOnSale()}
                    className="bg-accent-dark border border-accent-dark capitalize cursor-pointer rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
                  >
                    {t('put_sale')}
                  </button>
                </div>
              </div>
            </Spin>
          </div>
        </div>

        <button
          type="button"
          className="btn-close absolute top-6 right-6"
          onClick={() => setModal(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="h-6 w-6 fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      {/* <!-- end modal --> */}
    </div>
  );
};

export default PutOnSaleModal;
