

const Button = ({onClick, label}) => {
  return (
    <button
              onClick={onClick}
              className="bg-accent capitalize cursor-pointer rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
            >
              {label}
            </button>
  )
}

export default Button