import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { BsPencil } from "react-icons/bs"
import { LuCheckCircle } from "react-icons/lu"
import "tippy.js/dist/tippy.css";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import Error from "../errors/Error";
import Likes from "../likes";
import Auctions_dropdown from "../dropdown/Auctions_dropdown";
import { BASEURL_IMG } from "../../utils/constant";
import MintModal from "../modal/MintModal";
import CustomImage from "../global/CustomImage";
import { CONNECT_WALLET } from "../metamask/connector";
import PutOnSaleModal from "../modal/PutOnSaleModal";
import ViewBidsModal from "../modal/ViewBidsModal";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const NFTCard = ({ data, edit = false, type, refetch }) => {
  const {t,lang} = useTranslation('common')
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false)
  const router = useRouter();
  const pid = router.query.user;
  const [mintButtons, setMintButtons] = useState(true);
  const updateModal = () => {
    setModal(!modal)
  }
  const loginDetails = useSelector((state) => state.counter.loginDetails);
  console.log(typeof loginDetails?.profile?.id)
  useEffect(() => {
    const wallet = async () => {
      const res = await CONNECT_WALLET();
      console.log(res)
    }
    wallet();
  }, [])
  return (
    <>
      <div className="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
        {data?.status && data?.data?.length === 0 ? <Error msg="No NFT found" /> : data?.data?.map((item) => {
          const {
            id,
            localFile,
            nft_id,
            name,
            quantity,
            royalty,
            createdBy

          } = item;
          return (
            <article key={id}>
              <div className="dark:bg-black dark:border-accent-dark border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
                <figure className="relative">
                  <Link href={`/item/${nft_id}`} className="relative w-full h-[230px] block">
                    <CustomImage src={`${BASEURL_IMG}${localFile}`} fallback={'/images/logo.png'}  card={true} loaderWidth="!w-full" loaderHeight={230} NftCard={true} />

                  </Link>

                  {type == 'created' && item?.isMinted === 1 && <span className="absolute top-3 left-1.5 bg-green text-white text-xs font-medium rounded-lg px-3 py-1.5 shadow-lg flex items-center space-x-2"><LuCheckCircle /> <span className="capitalize">{t('minted')}</span></span>}
                  {edit && loginDetails?.profile?.id === item?.createdBy && item?.isMinted === 0 && mintButtons &&
                  <Link href={`/nft/${nft_id}`} className="text-sm absolute top-3 left-1.5  capitalize flex items-center space-x-2 bg-white rounded-lg px-3 py-1 border-jacarta-100 border shadow-xl hover:border-black hover:bg-black hover:text-white group dark:text-white dark:border-accent-dark dark:bg-accent-dark hover:dark:bg-black hover:dark:border-black dark:hover:text-white text-jacarta-500 transition-all"><span className="font-medium">{t('edit')}</span> 
                  <BsPencil className="!text-xs text-black dark:text-white hover:text-white group-hover:text-white transition-all" /></Link>}
                  <div className="flex justify-end absolute top-12 left-1.5">
                   {/* mint modal  */}
                   {item?.isMinted === 0 && type === 'created' && loginDetails?.profile?.id === item?.createdBy && mintButtons && <MintModal setMintButtons={setMintButtons} modalValue={modal} setModal={updateModal} nftName={name ? name : 'some name'} nftId={nft_id} quantity={quantity} royiti={royalty} />}
                </div>
                {type === 'owned' ? Number(pid) === Number(loginDetails?.profile?.id) ? <div className="absolute top-4 left-1.5">
                  <PutOnSaleModal modalValue={modal} setModal={updateModal} nftName={item?.name ? item?.name : 'some name'} nftId={item?.nft_id} quantity={item?.qty ? item?.qty : item?.quantity} />
                    </div> : '' : ''}
                  {type === 'sale' && <span className="absolute top-3 left-1.5 bg-green text-white text-xs font-medium rounded-lg px-3 py-1.5 shadow-lg flex items-center space-x-2">{t('on_sale')}</span>}
                  {type === 'sale' && item?.saleType?.toLowerCase() === 'bid' ? <div className="flex justify-end mt-3 cursor-pointer text-sm font-semibold capitalize absolute bottom-1.5 right-1.5">
                    <ViewBidsModal nft_id={item?.nft_id} user_id={item?.owner_id} />
                  </div> : ''}
                  <Likes isLiked={item?.isLiked} like={!item?.totalLike ? 0 : item?.totalLike} userId={loginDetails?.profile?.id} nftId={nft_id} />

                  <div className="absolute left-3 -bottom-3">
                    <div className="flex -space-x-2">
                      {item.nftOwners && item.nftOwners.map((userItem) => (
                        <Link href={`/user/${userItem.owner_id}`}>
                          <Tippy content={<span>{userItem.ownerName}</span>}>
                            <Image
                              width={21}
                              height={21}
                              src={userItem.logo == "null" || !userItem.logo ? '/images/avatars/user.jpeg'
                                : `${BASEURL_IMG}${userItem.logo}`}
                              alt="creator"
                              className="dark:border-jacarta-600 hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                            />
                          </Tippy>
                        </Link>
                      ))}
                    </div>
                  </div>
                </figure>
                <div className="mt-7 flex items-center justify-between">
                  <Link href={`/item/${nft_id}`}>
                    <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                      {name}
                    </span>
                  </Link>
                 


                </div>
               
                <div className="mt-1 text-sm flex justify-between items-center gap-3">

                  <div>
                    <span className="block text-xs font-semibold mb-1">
                      {/* Items : {!item?.quantity && !item?.qty ? 0 : item?.quantity && item?.quantity >= 0 ? item?.quantity : item?.qty && item?.qty >= 0 ? item?.qty : ''} */}
                      {t('price')} : {parseFloat(item?.price || 0).toFixed(6)} MATIC
                    </span>
                  </div>

                  <div>
                    <span className="inline-block px-3 py-1 rounded-full  text-xs font-medium">Items {item?.qty}</span>
                  </div>
                  
                </div>
                
              </div>
            </article>
          );
        })}
      </div>
    </>
  );
};

export default NFTCard;
