

const SaleType = ({value, onChange, name, label, options=[]}) => {
    return (
      <>
      <label
        htmlFor="item-name"
        className="font-display text-jacarta-700 mb-2 block dark:text-white"
      >
        {label}<span className="text-red">*</span>
      </label>
   
      <select
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        id="item-name"
        className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
        placeholder="Item name"
        required
      >
        {options.map(item =>(
            <option value={item}>{item}</option>
        ))}
      </select>
    </>
    )
  }
  
  export default SaleType