

const Input = ({value, onChange, name, label, type="text"}) => {
  return (
    <>
    <label
      htmlFor="item-name"
      className="font-display text-jacarta-700 mb-2 block dark:text-white"
    >
      {label}<span className="text-red">*</span>
    </label>
 
    <input
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      id="item-name"
      className="dark:bg-black border-accent-dark hover:ring-accent/10 focus:ring-accent dark:border-accent-dark dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
      placeholder={label}
      required
    />
  </>
  )
}

export default Input