import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import Tippy from "@tippyjs/react";
import { BsPencil } from "react-icons/bs"
import "tippy.js/dist/tippy.css";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import Error from "../errors/Error";
import Likes from "../likes";
import Auctions_dropdown from "../dropdown/Auctions_dropdown";
import { BASEURL_IMG } from "../../utils/constant";
import MintModal from "../modal/MintModal";
import CustomImage from "../global/CustomImage";
import { CONNECT_WALLET } from "../metamask/connector";
import PutOnSaleModal from "../modal/PutOnSaleModal";
import ViewBidsModal from "../modal/ViewBidsModal";

const NFTCardSlider = ({ item, edit = false, type }) => {
  console.log(item)
  const {
    id,

    localFile,
    nft_id,
    name,
    quantity,
    royalty,
    bidLimit,
    bidCount,
    likes,
    createdBy,
    owner,
  } = item;
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false)
  const updateModal = () => {
    setModal(!modal)
  }
  const loginDetails = useSelector((state) => state.counter.loginDetails);
  useEffect(() => {
    const wallet = async () => {
      const res = await CONNECT_WALLET();
      console.log(res)
    }
    wallet();
  }, [])
  return (
    <>
      <article key={id}>
        <div className="dark:bg-black dark:border-accent-dark border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg">
          <figure className="relative">
            <Link
              href={`/item/${nft_id}`}
              className="relative w-full h-[230px] block"
            >
              <CustomImage
                src={`${BASEURL_IMG}${localFile}`}
                fallback={"/images/logo.png"}
                loaderWidth="60"
                card={true}
                NftCard={true}
              />
            </Link>

            <Likes
              isLiked={item?.isLiked}
              like={!item?.totalLike ? 0 : item?.totalLike}
              userId={loginDetails?.profile?.id}
              nftId={nft_id}
            />

            <div className="absolute left-3 -bottom-3">
              <div className="flex -space-x-2">
                {item.nftOwners &&
                  item.nftOwners.map((userItem) => (
                    <Link href={`/user/${userItem.owner_id}`}>
                      <Tippy
                        content={
                          <span className="p-2">{userItem.ownerName}</span>
                        }
                      >
                        <Image
                          width={21}
                          height={21}
                          src={
                            userItem.logo == "null" || !userItem.logo
                              ? "/images/avatars/user.jpeg"
                              : `${BASEURL_IMG}${userItem.logo}`
                          }
                          alt="creator"
                          className="dark:border-accent-dark hover:border-accent dark:hover:border-accent h-6 w-6 rounded-full border-2 border-white"
                        />
                      </Tippy>
                    </Link>
                  ))}
              </div>
            </div>
          </figure>
          <div className="mt-7 flex items-center justify-between">
            <Link href={`/item/${nft_id}`}>
              <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                {name}
              </span>
            </Link>
            {/* mint modal  */}
            {item?.isMinted === 0 && type === "created" && (
              <MintModal
                modalValue={modal}
                setModal={updateModal}
                nftName={name ? name : "some name"}
                nftId={nft_id}
                quantity={quantity}
                royiti={royalty}
              />
            )}
            {type === "owned" && (
              <PutOnSaleModal
                modalValue={modal}
                setModal={updateModal}
                nftName={item?.name ? item?.name : "some name"}
                nftId={item?.nft_id}
                quantity={item?.qty ? item?.qty : item?.quantity}
              />
            )}

            {type === "sale" && item?.saleType?.toLowerCase() === "bid" ? (
              <div className="flex justify-end mt-3 cursor-pointer text-sm font-semibold capitalize">
                <ViewBidsModal nft_id={item?.nft_id} user_id={item?.owner_id} />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mt-2 text-sm flex justify-between items-center gap-3">
            <div>
              <span className="text-xs font-semibold mb-1 flex items-center text-black dark:text-white">
                <Image
                  src={"/polygon.png"}
                  height={20}
                  width={20}
                  alt="Edit pen icon"
                  className=" h-auto object-cover mr-2"
                />{" "}
                {parseFloat(item.price || 0).toFixed(6)} MATIC
              </span>
              <span className="dark:text-jacarta-300 text-jacarta-500"></span>
            </div>
            <div>
              <span className="inline-block px-3 py-1 rounded-full bg-black dark:bg-white dark:text-black text-white text-xs font-medium">
                {item?.items}
              </span>
            </div>
            {type == "created" && item?.isMinted && "Minted"}
            {edit &&
              loginDetails?.profile?.id === item?.createdBy &&
              item?.isMinted === 0 && (
                <Link
                  href={`/nft/${nft_id}`}
                  className="text-sm font-medium capitalize hover:text-black focus:text-black"
                >
                  <BsPencil className="!text-accent-dark" />
                </Link>
              )}
            {type === "sale" && (
              <span className="inline-block px-3 py-1 rounded-full bg-[#c7d2fe] text-[#3730a3] text-xs font-medium">
                On {item?.saleType}
              </span>
            )}
          </div>
        </div>
      </article>
    </>
  );
};

export default NFTCardSlider;
