import React, { useEffect, useState } from 'react'
import Input from '../inputs/Input'
import Button from '../buttons/Button'
import { ERROR, Success, Warning } from '../../utils/errors'
import { blockchainMint, getMaxRoyaltyAndBid } from './../metamask/blockchain';
import { setUserProcessing } from "../../redux/counterSlice";

import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';

const MintModal = ({ nftId, nftName, royiti, quantity,setMintButtons }) => {
  const {t,lang} = useTranslation('common')
  const UserProcessing = useSelector((state) => state.counter.UserProcessing);
  const dispatch = useDispatch();
  const [state, setState] = useState({
    quantity: '',
    royiti: ''
  })
  const [showLoading, setShowLoading] = useState(false)
  const [modalValue, setModal] = useState(false)
  const [MaxRoyalty, setMaxRoyalty] = useState(0);

  const onChange = e => {
    if (e.target.name == 'royiti' && e.target.value > MaxRoyalty) {
      return;
    }
    setState({ ...state, [e.target.name]: e.target.value })
  }
  useEffect(() => {
    setState({
      quantity,
      royiti
    })
  }, [])
  useEffect(() => {
    console.log(UserProcessing?.mint ,nftId)
    if (UserProcessing?.mint == nftId) {
      setMintButtons(false)
    }
  })

  const mintNow = async () => {
    if (parseFloat(state.quantity) == 0) {
      Warning('The quantity should be larger than 0');
      return;
    }

    setShowLoading(true)
    try {
      const res = await blockchainMint({
        quantity: state.quantity,
        nftId: nftId,
        royalty: state.royiti
      })

      if (res.status) {
        setMintButtons(false)
        dispatch(setUserProcessing({
          mint: nftId,
          hash: res.hash
        }));

        Success("Transaction successful, It will take few minutes for confirmation")
        setModal(false)
      } else {
        Warning(res.message)
      }

      setShowLoading(false)
    } catch (err) {
      Warning(err.message)

    }
  }
  const openModal = async () => {
    
    if (UserProcessing?.mint == nftId) {
      Warning("The transaction is under process, when it is minted you can see the NFT in the Owned NFT section.")
      return;
    }
    const res = await getMaxRoyaltyAndBid();
    if (res.status) {
      setModal(true);
      setMaxRoyalty(res.MaxRoyalty);
    } else {
      Warning(res.message)
    }
  }
  return (
    <div>
      <button onClick={() => openModal()} className='text-sm capitalize inline-block bg-white rounded-lg px-3 py-1 border-jacarta-100 border shadow-xl hover:border-black hover:bg-black dark:bg-accent-dark dark:border-accent-dark group dark:text-white hover:text-white group dark:hover:text-white text-jacarta-500 font-medium hover:dark:bg-black hover:dark:text-white hover:dark:border-black'>{t('mint')}  <span className='group-hover:pl-2 transition-all'>&rarr;</span></button>
      {/* <!-- Modal --> */}
      <div
        className={
          modalValue ? "modal fade show block" : "modal fade"
        }
      >
        <div className="!my-0 flex h-full w-full max-w-4xl items-center justify-center">
          <div className='w-full lg:w-8/12 px-3'>
            <Spin spinning={showLoading} tip="Loading...">
              <div className='bg-white dark:bg-[#0D102D] px-5 py-10 rounded-md'>
                <h1 className='text-xl'>{nftName}</h1>
                {/* <!-- Name --> */}
                <div className='my-5'>
                  <Input label={t('quantity')} value={state.quantity} onChange={onChange} name="quantity" />
                </div>
                <div className=''>
                  <Input label={t('royalty')} value={state.royiti} onChange={onChange} name="royiti" />
                  <small>{t('max_royalty')} {MaxRoyalty}%</small>
                </div>
                <div className='mt-6 flex justify-end gap-3'>
                  <button onClick={() => setModal(false)} className='bg-accent px-6 rounded-full text-white capitalize font-medium cursor-pointer'>
                    {t('cancel')}
                  </button>

                  <Button onClick={() => mintNow()} label={t('mint')} className='bg-accent cursor-pointer' />
                </div>
              </div>

            </Spin>

          </div>
        </div>

        <button
          type="button"
          className="btn-close absolute top-6 right-6"
          onClick={() => setModal(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="h-6 w-6 fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      {/* <!-- end modal --> */}
    </div>

  )
}

export default MintModal