import React, { useState } from "react";
import Link from "next/link"
import { useRouter } from "next/router";
import { WEBSITE } from "../../utils/constant";
import useTranslation from "next-translate/useTranslation";

const Auctions_dropdown = ({ classes }) => {
  const {t,lang} = useTranslation('nft')
  const [dropdownShow, setDropdownShow] = useState(false);
  const router = useRouter();
  const pid = router.query.item;

  const handleDropdown = () => {
    window.addEventListener("click", (w) => {
      if (w.target.closest(".trending-dots")) {
        if (dropdownShow) {
          setDropdownShow(false);
        } else {
          setDropdownShow(true);
        }
      } else {
        setDropdownShow(false);
      }
    });
  };
  return (
    <>
      <div className="relative">
        <div className={classes}>
          <button
            onClick={handleDropdown}
            className="dropdown-toggle trending-dots dark:bg-black border  border-jacarta-100 dark:border-accent-dark rounded-xl inline-flex h-10 items-center justify-center text-sm show !px-3"
          >
            {/* <svg
              width="16"
              height="4"
              viewBox="0 0 16 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="dark:fill-jacarta-200 fill-jacarta-500"
            >
              <circle cx="2" cy="2" r="2"></circle>
              <circle cx="8" cy="2" r="2"></circle>
              <circle cx="14" cy="2" r="2"></circle>
            </svg> */}
            {t('share')}
          </button>

          <div
            className={
              dropdownShow
                ? "dropdown-menu-end dark:bg-black border dark:border-accent-dark mt-1 z-10 min-w-[200px]  border-jacarta-100 whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl show text-jacarta-500 absolute top-full right-0"
                : "dropdown-menu-end dark:bg-jacarta-800 z-10 min-w-[200px] whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl hidden text-jacarta-500 absolute top-full right-0"
            }
          >

            <a target="_blank" href={`whatsapp://send?text=${WEBSITE}/item/${pid}`} className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-50 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white" on>
              Whatsapp
            </a>
            <a target="_blank" href={`https://twitter.com/intent/tweet?url=${WEBSITE}/item/${pid}`} className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-50 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
              Twitter
            </a>
        
            <a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${WEBSITE}/item/${pid}`} className="dark:hover:bg-jacarta-600 font-display hover:bg-jacarta-50 block w-full rounded-xl px-5 py-2 text-left text-sm transition-colors dark:text-white">
            Facebook
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Auctions_dropdown;
