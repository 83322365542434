import React, { useState, useEffect } from 'react'
import Web3 from 'web3';
import { config } from './config';

const { chainId, networkName, ABI, contractAddress } = config;



export const getMaxRoyaltyAndBid = async (req) => {
    if (window.ethereum) {
        var metamaskProvider = await window.ethereum;
        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0];

            const contract = await new web3.eth.Contract(ABI, contractAddress);
            const MaxRoyalty = await contract.methods.maxRoyalty().call();
            const minBidIncrease = await contract.methods.minBidIncrease().call();
            return {
                status: true,
                MaxRoyalty: MaxRoyalty / 100,
                minBidIncrease: minBidIncrease / 100
            }
        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}

export const blockchainMint = async (req) => {
    let quantity = req.quantity;
    let royalty = parseInt(parseFloat(req.royalty) * 10 ** 2).toString();
    let nftId = req.nftId;

    if (window.ethereum) {

        var metamaskProvider = await window.ethereum;

        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0]

            web3.eth.defaultAccount = fromAddress;
            const currentBalance = await web3.eth.getBalance(fromAddress);

            if (currentBalance == 0) {
                return {
                    status: false,
                    message: "Insufficient transaction fee in your wallet."
                }
            }

            const contract = await new web3.eth.Contract(ABI, contractAddress);
            let count = await web3.eth.getTransactionCount(fromAddress, 'pending');


            let gasPrice = await web3.eth.getGasPrice();
            const tx_builder = await contract.methods.mint(nftId, quantity, royalty);
            let encoded_tx = tx_builder.encodeABI();


            let gasLimit = await web3.eth.estimateGas({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                data: encoded_tx,
            });

            let trx = await web3.currentProvider.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: fromAddress,
                    nonce: web3.utils.toHex(count),
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    data: encoded_tx,
                }]
            })
            return {
                status: true,
                hash: trx
            }

            // const trxPromise = await new Promise((resolve, reject) => {
            //     contract.methods.mint(nftId, quantity, royalty).send({
            //         from: fromAddress,
            //         gasPrice: gasPrice,
            //         gas: web3.utils.toHex(gasLimit),
            //     }).on('receipt', (receipt) => {
            //         resolve(receipt);
            //     }).on('error', (error) => {
            //         resolve(error)
            //     });
            // });
            // if (trxPromise.transactionHash) {
            //     return {
            //         status: true,
            //         hash: trxPromise.transactionHash
            //     }
            // } else {
            //     return {
            //         status: false,
            //         message: (trxPromise.message) ? trxPromise.message : trxPromise.toString()
            //     }
            // }
        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}

export const blockchainPutOnSale = async (req) => {
    let saleType = req.saleType;
    let quantity = req.quantity;
    let startBid = req.startBid || 0;
    let endBid = req.endBid || 0;

    let price = parseInt(parseFloat(req.price) * 10 ** 18).toString();
    if (window.ethereum) {

        var metamaskProvider = await window.ethereum;

        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0]

            web3.eth.defaultAccount = fromAddress;
            const currentBalance = await web3.eth.getBalance(fromAddress);

            if (currentBalance == 0) {
                return {
                    status: false,
                    message: "Insufficient transaction fee in your wallet."
                }
            }

            const contract = await new web3.eth.Contract(ABI, contractAddress);
            let count = await web3.eth.getTransactionCount(fromAddress, 'pending');
            // let ownerOf = await contract.methods.ownerOf(req.nftId).call();
            // if(ownerOf.toUpperCase() != fromAddress.toUpperCase() ){
            //     return {
            //         status: false,
            //         message: "You don't have ownership of this NFT."
            //     }
            // }
            let gasPrice = await web3.eth.getGasPrice();
            
            const tx_builder = await contract.methods.putOnSale(req.nftId, quantity, saleType, price, startBid, endBid);
            let encoded_tx = tx_builder.encodeABI();


            let gasLimit = await web3.eth.estimateGas({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                data: encoded_tx,
            });

            let trx = await web3.currentProvider.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: fromAddress,
                    nonce: web3.utils.toHex(count),
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    data: encoded_tx,
                }]
            })
            return {
                status: true,
                hash: trx
            }

        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}


export const blockchainBuyAndBid = async (req) => {
    console.log({ req })
    let saleType = (req.saleType == 'SALE') ? 1 : 2;
    let quantity = req.qty;
    let sallerAddress = req.sallerAddress;
    let nft_id = req.nft_id;
    let payableAmount = req.payableAmount;

    payableAmount = parseInt(parseFloat(payableAmount) * 10 ** 18).toString();
    if (window.ethereum) {

        var metamaskProvider = await window.ethereum;

        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0]

            web3.eth.defaultAccount = fromAddress;
            const currentBalance = await web3.eth.getBalance(fromAddress);

            if (currentBalance == 0) {
                return {
                    status: false,
                    message: "Insufficient transaction fee in your wallet."
                }
            }

            const contract = await new web3.eth.Contract(ABI, contractAddress);
            let count = await web3.eth.getTransactionCount(fromAddress, 'pending');
            // let ownerOf = await contract.methods.ownerOf(req.nftId).call();
            // if(ownerOf.toUpperCase() != fromAddress.toUpperCase() ){
            //     return {
            //         status: false,
            //         message: "You don't have ownership of this NFT."
            //     }
            // }
            let gasPrice = await web3.eth.getGasPrice();

            let encoded_tx = '';
            if (saleType == 2) {
                let tx_builder = await contract.methods.bid(nft_id, sallerAddress, saleType);
                encoded_tx = tx_builder.encodeABI();
            } else {
                let tx_builder = await contract.methods.buyNft(nft_id, quantity, sallerAddress, saleType);
                encoded_tx = tx_builder.encodeABI();
            }


            let gasLimit = await web3.eth.estimateGas({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                value: web3.utils.toHex(payableAmount),
                to: contractAddress,
                data: encoded_tx,
            });

            let trx = await web3.currentProvider.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: fromAddress,
                    nonce: web3.utils.toHex(count),
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    value: web3.utils.toHex(payableAmount),
                    data: encoded_tx,
                }]
            })
            return {
                status: true,
                hash: trx
            }

        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}

export const blockchainCloseAuction = async (req) => {
    let saleType = 2;
    let nft_id = req.nft_id;

    if (window.ethereum) {

        var metamaskProvider = await window.ethereum;

        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0]

            web3.eth.defaultAccount = fromAddress;
            const currentBalance = await web3.eth.getBalance(fromAddress);

            if (currentBalance == 0) {
                return {
                    status: false,
                    message: "Insufficient transaction fee in your wallet."
                }
            }

            const contract = await new web3.eth.Contract(ABI, contractAddress);
            let count = await web3.eth.getTransactionCount(fromAddress, 'pending');
            // let ownerOf = await contract.methods.ownerOf(req.nftId).call();
            // if(ownerOf.toUpperCase() != fromAddress.toUpperCase() ){
            //     return {
            //         status: false,
            //         message: "You don't have ownership of this NFT."
            //     }
            // }
            let gasPrice = await web3.eth.getGasPrice();

            let tx_builder = await contract.methods.auctionClose(nft_id, fromAddress, saleType);
            let encoded_tx = tx_builder.encodeABI();



            let gasLimit = await web3.eth.estimateGas({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                data: encoded_tx,
            });

            let trx = await web3.currentProvider.request({
                method: 'eth_sendTransaction',
                params: [{
                    from: fromAddress,
                    nonce: web3.utils.toHex(count),
                    gasPrice: web3.utils.toHex(gasPrice),
                    gasLimit: web3.utils.toHex(gasLimit),
                    to: contractAddress,
                    data: encoded_tx,
                }]
            })
            return {
                status: true,
                hash: trx
            }

        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}



export const blockchainBuyNft = async (req) => {
    console.log("blockchainBuyNft", req)
    let saleType = (req.list_type == 'Sale') ? 1 : 2;


    let amount = parseInt((parseFloat(req.price) + 0.00001) * 10 ** 18).toString();
    if (window.ethereum) {

        var metamaskProvider = await window.ethereum;

        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0]

            web3.eth.defaultAccount = fromAddress;

            const contract = await new web3.eth.Contract(ABI, contractAddress);
            let count = await web3.eth.getTransactionCount(fromAddress, 'pending');

            let gasPrice = await web3.eth.getGasPrice();
            const tx_builder = await contract.methods.buy(req.nftId);
            let encoded_tx = tx_builder.encodeABI();


            let gasLimit = await web3.eth.estimateGas({
                value: web3.utils.toHex(amount),
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                data: encoded_tx,
            });

            const trxPromise = await new Promise((resolve, reject) => {
                contract.methods.buy(req.nftId).send({
                    value: web3.utils.toHex(amount),
                    from: fromAddress,
                    gasPrice: gasPrice,
                    gas: web3.utils.toHex(gasLimit),
                }).on('receipt', (receipt) => {
                    resolve(receipt);
                }).on('error', (error) => {
                    resolve(error)
                    console.log(error)
                });
            });
            if (trxPromise.transactionHash) {
                return {
                    status: true,
                    hash: trxPromise.transactionHash
                }
            } else {
                return {
                    status: false,
                    message: (trxPromise.message) ? trxPromise.message : trxPromise.toString()
                }
            }
        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}




export const blockchainPlaceBid = async (req) => {
    console.log(req)
    let saleType = (req.list_type == 'sale') ? 1 : 2;
    let amount = parseInt(parseFloat(req.amount) * 10 ** 18).toString();
    if (window.ethereum) {

        var metamaskProvider = await window.ethereum;

        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0]

            web3.eth.defaultAccount = fromAddress;

            const contract = await new web3.eth.Contract(ABI, contractAddress);
            let count = await web3.eth.getTransactionCount(fromAddress, 'pending');

            let gasPrice = await web3.eth.getGasPrice();
            const tx_builder = await contract.methods.placeBid(req.nftId);
            let encoded_tx = tx_builder.encodeABI();


            let gasLimit = await web3.eth.estimateGas({
                value: web3.utils.toHex(amount),
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                data: encoded_tx,
            });

            const trxPromise = await new Promise((resolve, reject) => {
                contract.methods.placeBid(req.nftId).send({
                    value: web3.utils.toHex(amount),
                    from: fromAddress,
                    gasPrice: gasPrice,
                    gas: web3.utils.toHex(gasLimit),
                }).on('receipt', (receipt) => {
                    resolve(receipt);
                }).on('error', (error) => {
                    resolve(error)
                    console.log(error)
                });
            });
            if (trxPromise.transactionHash) {
                return {
                    status: true,
                    hash: trxPromise.transactionHash
                }
            } else {
                return {
                    status: false,
                    message: (trxPromise.message) ? trxPromise.message : trxPromise.toString()
                }
            }
        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}



export const blockchainCancelBid = async (req) => {
    if (window.ethereum) {

        var metamaskProvider = await window.ethereum;

        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0]

            web3.eth.defaultAccount = fromAddress;

            const contract = await new web3.eth.Contract(ABI, contractAddress);
            let count = await web3.eth.getTransactionCount(fromAddress, 'pending');

            let gasPrice = await web3.eth.getGasPrice();
            const tx_builder = await contract.methods.cancelBid(req.nftId);
            let encoded_tx = tx_builder.encodeABI();


            let gasLimit = await web3.eth.estimateGas({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                data: encoded_tx,
            });

            const trxPromise = await new Promise((resolve, reject) => {
                contract.methods.cancelBid(req.nftId).send({
                    from: fromAddress,
                    gasPrice: gasPrice,
                    gas: web3.utils.toHex(gasLimit),
                }).on('receipt', (receipt) => {
                    resolve(receipt);
                }).on('error', (error) => {
                    resolve(error)
                    console.log(error)
                });
            });
            if (trxPromise.transactionHash) {
                return {
                    status: true,
                    hash: trxPromise.transactionHash
                }
            } else {
                return {
                    status: false,
                    message: (trxPromise.message) ? trxPromise.message : trxPromise.toString()
                }
            }
        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}


export const blockchainAcceptBid = async (req) => {

    if (window.ethereum) {

        var metamaskProvider = await window.ethereum;

        try {
            const accounts = await metamaskProvider.request({ method: 'eth_requestAccounts' });
            const web3 = new Web3(window.ethereum);

            if (web3.eth.currentProvider.networkVersion != chainId && web3.eth.currentProvider.chainId != web3.utils.toHex(chainId)) {
                return {
                    status: false,
                    message: `Please select Polygon ${networkName} network in your wallet `
                }
            }
            let fromAddress = accounts[0]

            web3.eth.defaultAccount = fromAddress;

            const contract = await new web3.eth.Contract(ABI, contractAddress);

            let ownerOf = await contract.methods.ownerOf(req.nftId).call();
            if (ownerOf.toUpperCase() != fromAddress.toUpperCase()) {
                return {
                    status: false,
                    message: "You don't have ownership of this NFT."
                }
            }

            let count = await web3.eth.getTransactionCount(fromAddress, 'pending');

            let gasPrice = await web3.eth.getGasPrice();
            const tx_builder = await contract.methods.acceptBid(req.nftId);
            let encoded_tx = tx_builder.encodeABI();


            let gasLimit = await web3.eth.estimateGas({
                from: fromAddress,
                nonce: web3.utils.toHex(count),
                gasPrice: web3.utils.toHex(gasPrice),
                to: contractAddress,
                data: encoded_tx,
            });

            const trxPromise = await new Promise((resolve, reject) => {
                contract.methods.acceptBid(req.nftId).send({
                    from: fromAddress,
                    gasPrice: gasPrice,
                    gas: web3.utils.toHex(gasLimit),
                }).on('receipt', (receipt) => {
                    resolve(receipt);
                }).on('error', (error) => {
                    resolve(error)
                    console.log(error)
                });
            });
            if (trxPromise.transactionHash) {
                return {
                    status: true,
                    hash: trxPromise.transactionHash
                }
            } else {
                return {
                    status: false,
                    message: (trxPromise.message) ? trxPromise.message : trxPromise.toString()
                }
            }
        } catch (err) {
            return {
                status: false,
                message: err.message
            }
        }
    } else {
        return {
            status: false,
            message: "Please install Metamask wallet to use this App"
        }
    }
}